<template>
    <v-app id="bg-app">
        
        <img src="/img/oie_QKIBhDK1OTHs.png" class="img-top" width="100%">
        <img src="/img/Group_1.png" class="bg-center">
        <div class="text-wrap">
            <div class="wrap-title">
                <div>
                    Kepribadianmu berdasarkan kebiasaan sehari-hari gigimu Kamu adalah…
                </div>
            </div>
            <div class="text-des">
                <div class="val">
                    <span class="top">
                    Si Kreatif & Santai
                    </span>
                    <span class="center">
                    Dari kepribadian kamu yang cukup peduli dengan kesehatan gigi, 
                    kamu punya lifestyle yang balance! 
                    <br><br>
                    Kamu merupakan orang kreatif yang suka menikmati hidup. 
                    Eits, tapi, jangan sampai lupa ya untuk tetap 
                    hidup lebih sehat dengan rajin merawat kesehatan 
                    gigimu supaya kamu tidak kehilangan senyum bebasmu!
                    </span>
                    <span class="improve">
                        Here’s how to <br> improve your smile
                    </span>

                    <div class="grouppanah">
                        <div class="bg-grouppanah"></div>
                    </div>
                    <v-btn
                        small
                        color="warning"
                        class="btn_result"
                        to="product-overviews-01"
                        >
                        Click here
                        </v-btn>
                </div>
            </div>

        </div>
        
        <img src="/img/oie_6ggndqwQKfUH.png" class="bg-buttom" width="100%">
        <div class="bg-frond"></div>
    </v-app>
</template>

<script>
export default {
  name: 'SubmitQuestion',
  methods: {
    changeColor(){
      var metaThemeColor = document.querySelector("meta[name=theme-color]");
          metaThemeColor.setAttribute("content", "#3E8AC6");
    }
  },
  created() {
      this.changeColor();
  },
}
</script>


<style lang="stylus">
    @font-face {
        font-family: Caveat;
        src: url('/fonts/Caveat.ttf');
    }
    @font-face {
        font-family: CaveatBrush;
        src: url('/fonts/CaveatBrush.ttf');
    }
    .img-top{
        position : fixed;
        top:0;
    }
    .bg-frond{
        width : 100%;
        height : 100%;
        position : fixed;
        z-index : 88;
    }
    .text-wrap{
        font-family : Caveat;
        z-index : 99;
        width : 300px;
        margin : 0 auto;
        text-align :center;
        font-size: 26px;
        color : #fff;
        line-height : 28px;
        margin-top : -505px;
        font-weight: bold;
        font-style: normal;
    }
    #bg-app {
        width: 100%;
        height: 100%;
        background : #3E8AC6;
        position : fixed;
        z-index : 87;
        padding-top : 120px;
    }
    .bg-buttom{
        position :absolute;
        bottom : 0;
        z-index : 87;
        position : fixed;
        bottom : 0;
    }
    .bg-center{
        width : 330px;
        Height : 429.02px;
        margin : 0 auto;
        z-index : 88;
        margin-top : 70px;
    }
    .text-des{
        padding : 0 35px 0 22px;
        margin-top : 26px;
        color : #000;
        font-size: 18px;
    }
    .text-des .top{
        display : block;
        padding-bottom: 20px;
        line-height : 18.3px;
    }
    .text-des .center{
        display : block;
        margin-bottom: 15px;
        line-height : 18.3px;
    }
    .improve{
        font-family : CaveatBrush;
        font-weight : normal;
        text-transform:uppercase;
        padding : 0 30px;
        display :block;
        line-height : 18.3px;
    }

    .grouppanah{
        width : 338px;
        margin : 0 auto;
    }
    .bg-grouppanah{
        background-image : url('/img/Grouppanah.png');
        background-size contain;
        width : 60px;
        height : 60px;
        position : absolute;
        margin-left :0px;
        margin-top : -15px;
    }

    .btn_result{
        margin-top : 25px;
        padding : 10px 40px !important;
        border-radius:10px;
    }
</style>